import { EventInput } from '@fullcalendar/core';
import { ICalendarEvent } from './Scheduler.interface';

export type FormValuesProps = ICalendarEvent;

export type CalendarFormProps = {
  selectedEvents: EventInput[] | null | undefined;
  onCancel: VoidFunction;
  onDeleteEvent: VoidFunction;
  onCreateUpdateEvent?: (newEvents: EventInput[]) => void;
};

export interface DayOption {
  date: string;
  value: string;
  selected: boolean;
}

export enum Colors {
  BREAK = '#D1C4E9',
  REGULAR = '#DFEBFF',
  REST = '#D8D8D8',
  EXTRA = '#FFDCB9',
  CHANGE = '#FFDCB9',
  SUCCESSFUL = '#BDE8BD',
  UNAVAILABLE = '#D8D8D8',
}

export enum SchedulerStatusColors {
  DRAFT = '#FFCC47',
  REVISION = '#F49769',
  PUBLISHED = '#83D783',
  FINALIZED = '#D8D8D8',
  CANCELLED = '#FFDCB9',
}
