import { z } from 'zod';
import {
  ShiftInterface,
  IntervalsTypes as IntervalsEnum,
  ShiftsTypes as ShiftsEnum,
  RotationTypes as RotationEnum,
  Period as PeriodInterface,
  Day as DayInterface,
  ShiftEntity,
} from './interfaces/shifts.interface';
import { BreaksFormInterface } from 'modules/setup-config/interfaces/configuratorBreaks.interfaces';
import moment from 'moment';
import { formatDate, FormatDateOutputSlash } from 'utils';

export const CONFIGURATOR_TITLE = 'Tipo de turno';

export const rotationWithId = [
  {
    id: RotationEnum.WEEK,
    name: 'Semanal',
  },
  {
    id: RotationEnum.FORTNIGHT,
    name: 'Quincenal',
  },
  {
    id: RotationEnum.MONTH,
    name: 'Mensual',
  },
];

export const daysOfWeek = [
  { name: 'Lunes', value: 'monday', id: 'mon' },
  { name: 'Martes', value: 'tuesday', id: 'tue' },
  { name: 'Miercoles', value: 'wednesday', id: 'wed' },
  { name: 'Jueves', value: 'thursday', id: 'thu' },
  { name: 'Viernes', value: 'friday', id: 'fri' },
  { name: 'Sabado', value: 'saturday', id: 'sat' },
  { name: 'Domingo', value: 'sunday', id: 'sun' },
];

export const AutocompleteDays: DayInterface[] = [
  { id: 0, name: 'Lunes', work: true, ingress: '', egress: '' },
  { id: 1, name: 'Martes', work: true, ingress: '', egress: '' },
  { id: 2, name: 'Miércoles', work: true, ingress: '', egress: '' },
  { id: 3, name: 'Jueves', work: true, ingress: '', egress: '' },
  { id: 4, name: 'Viernes', work: true, ingress: '', egress: '' },
  { id: 5, name: 'Sábado', work: true, ingress: '', egress: '' },
  { id: 6, name: 'Domingo', work: true, ingress: '', egress: '' },
];

export const shiftTypesWithId = [
  {
    id: ShiftsEnum.REGULAR,
    name: 'Regular',
  },
  {
    id: ShiftsEnum.ROTATIVO,
    name: 'Rotativo',
  },
];

export const headCells = [
  {
    id: '1',
    label: '',
    numeric: false,
    sortable: false,
  },
  {
    id: 'name',
    label: 'Nombre del turno',
    numeric: false,
    sortable: false,
  },
  {
    id: 'type',
    label: 'Tipo de turno',
    numeric: false,
    sortable: false,
  },
  {
    id: 'hour_amount',
    label: 'Cant. de horas',
    numeric: false,
    sortable: false,
  },
  {
    id: 'interval_type',
    label: 'Tipo de descanso',
    numeric: false,
    sortable: false,
  },
  {
    id: 'rest_days',
    label: 'Días de descanso',
    numeric: false,
    sortable: false,
  },
  {
    id: '2',
    label: '',
    numeric: false,
    sortable: false,
  },
  {
    id: '3',
    label: '',
    numeric: false,
    sortable: false,
  },
];
// Enums as Zod schemas
const ShiftsTypes = z.enum(['regular', 'rotativo', 'partido']);
const IntervalsTypes = z.enum(['regular', 'rotativo']);
const RotationTypes = z.enum(['week', 'fortnight', 'month']);
// Day schema
const Day = z.object({
  id: z.number(),
  name: z.string(),
  work: z.boolean(),
  ingress: z.date().nullable(),
  egress: z.date().nullable(),
});
// Period schema
const Period = z.object({
  id: z.number(),
  days: z.array(Day),
});
// MainBreak schema
const MainBreak = z.object({
  id: z.string(),
  name: z.string(),
  category: z.string(),
});
const MainBreakArraySchema = z.array(MainBreak).min(1, { message: 'Debe seleccionar al menos una opción' });
// ShiftInterface schema
export const ShiftSchema = z
  .object({
    id: z.string().optional(),
    name: z.string().min(5, 'Ingrese un nombre de turno'),
    shift_type: ShiftsTypes.or(z.literal('')),
    periods: z.array(Period),
    period_rotate_each: RotationTypes.or(z.literal('')).optional(),
    period_start: z.string().min(5, 'Ingrese una fecha válida'),
    period_end: z
      .string()
      .nullable()
      .optional(),
    status: z.boolean(),
    resting_type: IntervalsTypes.or(z.literal('')),
    resting_period_rotate_each: z.string().optional(),
    resting_period_amount: z
      .string()
      .or(z.number())
      .optional(),
    breaks: MainBreakArraySchema,
    hourly_rate: z
      .union([
        z.number(),
        z
          .string()
          .optional()
          .transform(val => (val === '' ? null : val)),
      ])
      .nullable(),
    percentage_hourly_rate: z
      .number()
      .optional()
      .nullable(),
  })
  .refine(
    data => {
      if (data.shift_type === 'rotativo') {
        return data.period_rotate_each !== undefined;
      }
      return true;
    },
    {
      message: 'Requerido',
      path: ['period_rotate_each'],
    },
  )
  .refine(
    data => {
      if (data.resting_type === 'rotativo') {
        return data.resting_period_amount !== undefined;
      }
      return true;
    },
    {
      message: 'Requerido',
      path: ['resting_period_amount'],
    },
  )
  .refine(
    data => {
      if (data.resting_type === 'rotativo') {
        return data.resting_period_rotate_each !== undefined;
      }
      return true;
    },
    {
      message: 'Requerido',
      path: ['resting_period_rotate_each'],
    },
  );
export const getDisabledDays = (newSelectedShift: ShiftInterface) => {
  const newDisabledDays: DayInterface[] = [];
  newSelectedShift.periods.forEach(period => {
    period.days.forEach(day => {
      if (!day.work) {
        newDisabledDays.push(day);
      }
    });
  });
  return newDisabledDays;
};
export const sanitizeIntervals = (breaks: BreaksFormInterface) => {
  const dataToReturn = [];
  // Sanitize the main break and add the category
  const mainSanitized = {
    id: breaks.main.id,
    name: breaks.main.name,
    category: 'main', // Added category property
  };
  // Add the sanitized main break to the array
  dataToReturn.push(mainSanitized);
  // Map over the intervals and add each one to the array with the category
  breaks.intervals.forEach(interval => {
    dataToReturn.push({
      id: interval.id,
      name: interval.name,
      category: 'interval', // Added category property
    });
  });
  return dataToReturn;
};
export const calculateTimeDifferenceInView = (startTime: string, endTime: string): string => {
  const startMoment = moment(startTime, 'HH:mm');
  const endMoment = moment(endTime, 'HH:mm');
  const durationMinutes = endMoment.diff(startMoment, 'minutes');
  const hours = Math.floor(durationMinutes / 60);
  const minutes = durationMinutes % 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};
export const emptyShiftModel: ShiftInterface = {
  name: '',
  shift_type: ShiftsEnum.REGULAR,
  periods: [
    {
      id: 0,
      days: [
        {
          id: 0,
          name: 'monday',
          work: true,
          ingress: '',
          egress: '',
        },
        {
          id: 1,
          name: 'tuesday',
          work: true,
          ingress: '',
          egress: '',
        },
        {
          id: 2,
          name: 'wednesday',
          work: true,
          ingress: '',
          egress: '',
        },
        {
          id: 3,
          name: 'thursday',
          work: true,
          ingress: '',
          egress: '',
        },
        {
          id: 4,
          name: 'friday',
          work: true,
          ingress: '',
          egress: '',
        },
        {
          id: 5,
          name: 'saturday',
          work: true,
          ingress: '',
          egress: '',
        },
        {
          id: 6,
          name: 'sunday',
          work: true,
          ingress: '',
          egress: '',
        },
      ],
    },
  ],
  period_count: 0,
  period_start: '',
  period_end: null,
  status: false,
  resting_type: IntervalsEnum.REGULAR,
  resting_period_rotate_each: undefined,
  resting_period_amount: undefined,
  breaks: [],
  resting_days: '',
  hourly_rate: undefined,
  percentage_hourly_rate: undefined,
};
export const emptyShiftSliceModel: ShiftEntity = {
  name: '',
  shift_type: ShiftsEnum.REGULAR,
  periods: [
    {
      id: 0,
      days: [
        {
          id: 0,
          name: 'monday',
          work: true,
          ingress: '',
          egress: '',
        },
        {
          id: 1,
          name: 'tuesday',
          work: true,
          ingress: '',
          egress: '',
        },
        {
          id: 2,
          name: 'wednesday',
          work: true,
          ingress: '',
          egress: '',
        },
        {
          id: 3,
          name: 'thursday',
          work: true,
          ingress: '',
          egress: '',
        },
        {
          id: 4,
          name: 'friday',
          work: true,
          ingress: '',
          egress: '',
        },
        {
          id: 5,
          name: 'saturday',
          work: true,
          ingress: '',
          egress: '',
        },
        {
          id: 6,
          name: 'sunday',
          work: true,
          ingress: '',
          egress: '',
        },
      ],
    },
  ],
  period_count: 0,
  period_start: '',
  period_end: null,
  status: false,
  resting_type: IntervalsEnum.REGULAR,
  resting_period_rotate_each: undefined,
  resting_period_amount: undefined,
  resting_days: '',
  breaks: [],
  hourly_rate: undefined,
  percentage_hourly_rate: undefined,
  total_days_periods: 0,
  total_hours_periods: 0,
  total_minutes_periods: 0,
};
export const dataSanitizer = (
  data: ShiftInterface,
  operationMode: 'stringToDate' | 'dateToString',
  disabledDays?: number[],
): ShiftInterface => {
  let newDate: string | undefined;
  if (operationMode === 'dateToString') {
    newDate = formatDate(data.period_start.replaceAll('-', '/'), FormatDateOutputSlash.YYYYMMDD)?.replaceAll('/', '-');
  } else {
    newDate = formatDate(data.period_start.replaceAll('-', '/'), FormatDateOutputSlash.YYYYMMDD);
  }
  const newPeriods = data.periods.map((period: PeriodInterface) => {
    const newDays = period.days.map((day: DayInterface) => {
      let isDisabled;
      if (disabledDays) {
        isDisabled = !disabledDays.includes(day.id);
      } else {
        isDisabled = day.work;
      }
      let ingress, egress;
      if (operationMode === 'stringToDate') {
        ingress = day.ingress ? moment(day.ingress, 'HH:mm').toDate() : null;
        egress = day.egress ? moment(day.egress, 'HH:mm').toDate() : null;
      } else if (operationMode === 'dateToString') {
        ingress = day.ingress instanceof Date ? moment(day.ingress).format('HH:mm') : day.ingress;
        egress = day.egress instanceof Date ? moment(day.egress).format('HH:mm') : day.egress;
      } else {
        ingress = day.ingress;
        egress = day.egress;
      }
      return {
        ...day,
        work: isDisabled,
        ingress,
        egress,
      };
    });
    return {
      ...period,
      days: newDays,
    };
  });
  return {
    ...data,
    period_start: newDate ? newDate : data.period_start,
    periods: newPeriods,
  };
};
